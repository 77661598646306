import './styles/App.css';
import { Route, Routes } from 'react-router-dom';
import { Home, Product, LandingProduct, Order, Products } from './pages';
import ReactPixel from 'react-facebook-pixel';
import { useEffect, useState } from 'react';
import axios from 'axios';
import useStore from './store/useStore';
import { Helmet } from 'react-helmet';
import { Box, Image } from '@mantine/core';
import { IconBrandWhatsapp } from '@tabler/icons-react';

function App() {
    const dataStore = useStore((state: any) => state.store);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/store`)
        .then(({data}) => {
            // cookies.set('store', JSON.stringify(dataStore));
            useStore.setState({ store: data });
        })
        .catch((error) => {
            console.log(error);
        })
    }, [])
    
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/pixels`)
        .then(({data}) => {
            // const facebookData = data.filter((item: any) => item.name === "facebook")
            // console.log(facebookData);
            // setFacebookToken(facebookData?.[0]?.apiKey)
            const advancedMatching = undefined;
            data?.map((item: any, index: number) => {
                if (item?.apiKey !== "" && item?.apiKey.length > 10 && !item?.idMarketer) {
                    ReactPixel.init(item?.apiKey, advancedMatching, {
                        autoConfig: true,
                        debug: true
                    });
                }
            })
            ReactPixel.pageView();
        })
        .catch((error) => console.log(error));
    }, [])

    return (
        <>
            <Helmet>
                <link rel="icon" href={dataStore?.icon && dataStore?.icon !== "" ? `${process.env.REACT_APP_API_URL_IMAGES}/${dataStore?.icon}` : "/favicon.ico"} />
                <title>{dataStore?.information?.title || ""}</title>
                <meta name="description" content={dataStore?.information?.shortDescription || ""} />
            </Helmet>
            <Routes>
                <Route index path="/" element={<Home />} />
                <Route index path="/product/:id" element={<Product />} />
                <Route index path="/products/:category" element={<Products />} />
                <Route index path="/:id" element={<LandingProduct />} />
                <Route index path="/order/:status/:type?/:id?/:isMany?/:ref?" element={<Order />} />
            </Routes>
            
            {dataStore?.information?.phone
                ? <Box style={{position: "fixed", bottom: 20, right: 20}}>
                    <Box 
                        className='pulse-button-whatsapp' w={50} h={50} bg={"#26d469"} display={'flex'} style={{justifyContent: 'center', alignItems: 'center', borderRadius: "50%"}}
                        component='a' target='_blank'
                        href={`https://wa.me/${dataStore?.information?.phone}`}
                    >
                        <IconBrandWhatsapp size={30} color='#fff' />
                    </Box>
                </Box>
                : null
            }
        </>
    );
}

export default App;
